<script lang='ts'>
  import { ChartConfiguration } from 'chart.js'

  import { DOUBLE_DECIMAL_NUMBER } from '@/config/constants'

  import { fetchData } from '@/helpers/fetchHelpers'
  import { _ } from '@/helpers/i18n'

  import { Goal } from '@/definitions/langoid'

  type Summary = Record<string, {
    day: string
    delta: number
    deltaExpected: number
    deltaSum: number
    done: number
    index: number
    track: string
  }>

  export let id = 0

  let goal: Goal = {} as Goal
  let summary: Summary
  let chartEl: HTMLCanvasElement
  let chart: any
  let daysArray: string[]
  const SHORT_DATE_LENGTH = 10
  const today = new Date().toISOString().slice(0, SHORT_DATE_LENGTH)
  $:if (id) {
    reloadPage(id)
  }

  const style = getComputedStyle(document.body)

  function calculateSummary (summary: Summary, goal: Goal, daysArray: string[]) {
    let deltaSum = 0
    daysArray.forEach((day, index) => {
      const deltaExpected = index * goal.daily
      const done = summary[day]?.done || 0
      deltaSum += done
      const track = ((deltaSum - deltaExpected) / goal.daily).toFixed(DOUBLE_DECIMAL_NUMBER)
      summary[day] = {
        ...summary[day],
        day,
        delta: 0,
        deltaExpected,
        deltaSum,
        done,
        index,
        track
      }
    })
    return summary
  }

  function calculateBurnDownData (goal: Goal, daysArray: string[], summary: Summary, today: string) {
    const expected = []
    const labels = []
    const progress = [goal.size]
    let remainingProgress = goal.size
    let dayCount = 1
    const perDay = goal.size / goal.days

    for (let i = goal.size; i > 0; i -= perDay) {
      const day = daysArray[dayCount - 1] || ''
      const doneToday = summary[day]?.done || 0
      remainingProgress -= doneToday

      expected.push(i)
      labels.push(`${dayCount++}`)

      if (day.replace(/:/g, '') <= today.replace(/:/g, '')) {
        progress.push(remainingProgress)
      }
    }

    return {
      expected,
      labels,
      progress
    }
  }

  async function reloadPage (id: number) {
    const data = await fetchData('main/goalLoad', { id })
    goal = data.goal
    daysArray = data.days
    summary = calculateSummary(data.summary, goal, daysArray)
    const burnDownData = calculateBurnDownData(goal, daysArray, summary, data.today || '')
    showBurnDown(goal.days, burnDownData.expected, burnDownData.labels, burnDownData.progress)
  }

  async function showBurnDown (days: number, expected: number[], labels: string[], myProgress: number[]) {
    const { Chart } = await import('@/helpers/chart')
    if (chart) chart.destroy() // clear the canvas
    const maxValue: number = expected[0]
    const newExpected: number[] = expected.map(el => 100 - Math.round(el / maxValue * 10000) / 100)
    const newProgress: number[] = myProgress.map(el => 100 - Math.round(el / maxValue * 10000) / 100)
    const pointBackgroundColor = myProgress.map((el, index) => expected[index] < myProgress[index] ? style.getPropertyValue('--Error-Medium') : style.getPropertyValue('--Success-Medium'))
    const chartSetup: ChartConfiguration<'line'> = {
      data: {
        datasets: [
          {
            borderColor: style.getPropertyValue('--Gray-Medium'),
            borderWidth: 1,
            data: newProgress,
            label: $_('goal.percentLeft'),
            pointBackgroundColor,
            pointBorderColor: 'transparent',
            pointRadius: 3,
            tension: 0.1
          },
          {
            borderColor: style.getPropertyValue('--Gray-Dark'),
            borderWidth: 1,
            data: newExpected,
            label: $_('goal.expectedPercent'),
            pointRadius: 0,
            tension: 0
          }
        ],
        labels
      },
      options: {
        backgroundColor: style.getPropertyValue('--Gray-Lighter'),
        borderColor: 'transparent',
        color: style.getPropertyValue('--Gray-Dark'),
        interaction: {
          intersect: true,
          mode: 'index'
        },
        responsive: true,
        scales: {
          x: {
            display: true, title: { display: true, text: 'Day' },
          },
          y: {
            display: true, max: 110,
            min: 0,
            title: { display: true, text: 'Percent' }
          }
        },
        spanGaps: true
      },
      type: 'line'
    }
    chart = new Chart(chartEl, chartSetup)
  }
</script>
<div class='goal-set'>
  <div class='canvas-wrapper'>
    <canvas bind:this={chartEl} />
  </div>

  {#if goal.id}
    <h2 class='goal-title'>{$_('goal.learnInXDaysTillLevelY', {
      values: {
        days: goal.days,
        language: goal.language,
        level: goal.level
      }
    })}</h2>
    <table class='_table -lessBorders'>
      <tr>
        <th>{$_('period.day')}</th>
        <th>{$_('mix.date')}</th>
        <th>{$_('goal.daysOnTrack')}</th>
        <th>{$_('goal.daily')}</th>
      </tr>
      {#each daysArray.filter(d => d <= today).reverse() as day}
        {@const item = summary[day]}
        <tr class='table-row'>
          {#if item}
            <td>{item.index}</td>
            <td>{day}</td>
            <td class='track' class:-positive={parseInt(item.track) > 0}>{item.track}</td>
            <td class='percent' class:-done={item.done >= goal.daily} class:-zero={item.done === 0}>
              {item.done > 0 ? (item.done * 100 / goal.daily).toFixed(DOUBLE_DECIMAL_NUMBER) + '%' : ''}
            </td>
            <!--
          <td>{item.delta}</td>
          <td>{item.deltaSum}</td>
          <td>{item.deltaExpected}</td>
          <td>{item.done}</td>
          -->
          {/if}
        </tr>
      {/each}
    </table>
    {#if Object.keys(summary).length === 0}
      <p>{$_('goal.noData')}</p>
    {/if}
  {/if}
</div>

<style lang='scss'>
  .goal-set {
    max-width: 68.4rem;
    margin: auto;
  }

  .goal-title {
    margin: 2.4rem 0;
    padding: 1.8rem;
    font-weight: bold;
    text-align: center;
    color: var(--Warning-Dark);
    background: var(--Warning-Lighter);
    border: 0.1rem solid var(--Warning-Lighter);
    border-radius: 1.2rem;
    box-shadow: var(--light-box-shadow);
  }

  .table-row {
    > .percent {
      font-weight: bold;
      text-align: right;
      color: var(--inverted-text-color);
      background: var(--Error-Light);

      &.-done {
        background: var(--Success-Light);
      }

      &.-zero {
        background: var(--Error-Medium);
      }
    }

    > .track {
      font-weight: bold;
      text-align: right;
      color: var(--Error-Medium);

      &.-positive {
        color: var(--Success-Light);
      }
    }
  }

  .canvas-wrapper {
    width: 100%;
    max-width: 76.8rem;
  }
</style>
